<template>
  <div class="about">
      <van-nav-bar
      title="全部"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="gld" />
    <!-- 列表 -->
    <div class="list-box">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          >
          <ul class="list-content">
              <li class="first-li"><span>头像</span><span>昵称</span><span>手机号</span><span>下单时间</span></li>
              <li class="other-li" v-for="(value, index) in list" :key="index">
                  <span class="first-span"><img :src="value.avatar"></span>
                <span class="ellipse1">{{value.real_name}}</span>
                <span>{{value.mobile}}</span>
                <span>{{value.pay_time}}</span>
              </li>
          </ul>
          </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>


<script>
import { getlist } from '@plugins/server'

  export default {
    name: 'All',
    data() {
      return {
        code: require('@assets/img/code.png'),
        list: [],
        refreshing: false,
        loading: false,
        finished: false,
        page_no: 2
      }
    },
    mounted() {
      this.getUserList({page_no: 1})
    },
    methods: {
      onClickLeft() {
        this.$router.go(-1)
      },
      // 初始化数据
      getUserList(data) {
          getlist({page_no: data.page_no, page_size: 20, activity_id: this.$route.query.activity_id}).then(res => {
              this.list = res.data
          })
      },
      // 上拉加载
      onLoad() {
          setTimeout(() => {
              if (this.refreshing) {
                  this.list = [];
                  this.refreshing = false;
              }
              getlist({page_no: this.page_no, page_size: 20, activity_id: this.$route.query.activity_id}).then(res => {
                  if (res.data.length > 0) {
                      this.list.push(...res.data)
                      this.page_no++
                      this.loading = false;
                  } else {
                      this.finished = true;
                  }
              })
          }, 1000 )
      },

      onRefresh() {
          this.page_no = 1
          setTimeout(() => {
              this.finished = false;
              this.loading = true;
              this.onLoad()
              this.$toast.success('刷新成功');
          }, 1000);
      },
    }
  }
</script>

<style lang="scss" scoped>
    .van-nav-bar {
        z-index: 999;
        height: 45px;
        /deep/ .van-nav-bar__title {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #202020;
        }
        /deep/ .van-nav-bar__arrow {
          font-weight: bold;
          color: #202020;
          font-size: 18px;
        }
    }
  .gld {
    height: 12px;
    background-color: #f8f8f8;
  }
  .list-box {
          background-color: #fff;
          padding: 0 15px;
          .list-content {
              .first-li {
                  height: 55px;
                  display: flex;
                  justify-content: space-between;
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: bold;
                  color: #545454;
                  line-height: 55px;
                  span {
                      text-align: left;
                      &:nth-of-type(2) {
                          // margin-right: 50px;
                          padding-right: 5px;
                      }
                      &:nth-of-type(3) {
                          margin-right: 35px;
                          margin-left: 25px;
                      }
                      &:nth-of-type(4) {
                          padding-right: 15px;
                      }
                  }
              }
              .other-li {
                  height: 55px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #545454;
                  span {
                      text-align: left;
                      &:nth-of-type(2) {
                          width: 50px;
                      }
                  }
                  .first-span {
                      width: 35px;
                      height: 35px;
                      border-radius: 50%;
                      img {
                          width: 100%;
                          height: 100%;
                          border-radius: 50%;
                      }
                  }
              }
          }
    }
</style>
